/*
 * @Author: mengying.yao
 * @Email: mengying.yao@medbanks.cn
 * @Date: 2020-09-14 14:57:16
 * @Last Modified by: shuwen.chen
 * @Last Modified time: 2020-12-31 18:08:53
 * @Description: Description
 * @Route: Route
 */


<template>
  <div class="question_details">
    <h3 class="header_name">
      问题设置
    </h3>
    <div class="question-list">
      <el-form ref="form"
               :rules="rules"
               :model="questionData"
               label-width="100px">
        <div class="questionForm">
          <h2 class="title">
            问题设置
          </h2>
          <div class="set_wrap">
            <el-form-item label="内部编号：">
              <el-input v-model="questionData.internalCode"
                        @blur="codeBlurHandle"
                        placeholder="请输入内部编号" />
            </el-form-item>
            <el-form-item label="问题标题"
                          prop="title">
              <el-input v-model="questionData.title"
                        placeholder="请输入问题标题" />
            </el-form-item>
            <el-form-item label="答复："
                          prop="title">
              <div class="edit_wrap">
                <div ref="editor"
                     v-loading="videoUploadLoading"
                     element-loading-text="视频上传中..."
                     style="text-align: left;" />
              </div>
            </el-form-item>
            <el-form-item label="上传附件：">
              <div class="edit_wrap">
                <el-upload :http-request="uploadImg"
                           :before-upload="beforeCoverUpload"
                           :limit="5"
                           :before-remove="removeHandle"
                           :file-list="fileList"
                           action="">
                  <el-button class="upload-btn"
                             size="small"
                             type="primary">
                    点击上传
                  </el-button>
                  <div slot="tip"
                       class="el-upload__tip">
                    支持扩展名：doc,docx ,pdf,jpg,xls,xlsx,ppt等
                  </div>
                </el-upload>
              </div>
            </el-form-item>

            <el-form-item label="所属分类"
                          prop="classificaCode">
              <el-select v-model="questionData.classificaCode"
                         :remote-method="getClassListMethod"
                         clearable
                         filterable
                         remote
                         placeholder="请选择分类">
                <el-option v-for="item in classList"
                           :key="item.classificaCode"
                           :label="item.classificaName"
                           :value="item.classificaCode" />
              </el-select>
            </el-form-item>
            <el-form-item label="切换状态"
                          prop="switchState">
              <el-radio-group v-model="questionData.switchState">
                <el-radio :label="0">
                  开启
                </el-radio>
                <el-radio :label="1">
                  关闭
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="标签">
              <el-checkbox-group v-model="questionData.labelList">
                <el-checkbox :label="1">
                  个人投保
                </el-checkbox>
                <el-checkbox :label="2">
                  企业投保
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>

        <div class="questionForm mt20">
          <div class="title">
            知识来源
          </div>
          <div class="set_wrap">
            <el-form-item label="业务来源"
                          prop="relationOriginProblemList">
              <el-cascader v-show="!questionData.channelName || sourceEditing || copy"
                           v-model="questionData.relationOriginProblemList"
                           :props="{
                             checkStrictly : true,
                             multiple: true,
                             value : 'id',
                             label : 'channelName',
                             emitPath : false,
                             children : 'customerOriginChildrenRespDTOList',
                             lazy : true,
                             lazyLoad : getSourceList
                           }"
                           :options="sourceList"
                           placeholder="请选择业务来源"
                           clearable />

              <div v-if="questionData.channelName && !sourceEditing && !copy">
                {{ questionData.channelName }}
                <el-button @click="sourceEditing = true"
                           type="text">
                  编辑
                </el-button>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="save_btn">
        <el-button @click="saveHandle"
                   type="primary">
          提交
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { Loading } from 'element-ui';
import E from '@med/editor';
import { mapActions, mapGetters } from 'vuex';
import { question } from '@/api';
import { analysisImg } from '@/utils/util';

export default {
  data() {
    return {
      sourceEditing: false,
      classList: [],
      sourceList: [],
      questionData: {
        classificaCode: '',
        switchState: '',
        title: '',
        labelList: [],
        reply: '',
        relationOriginProblemList: [],
        attachment: [],
      },
      rules: {
        classificaCode: [{ required: true, message: '请选择所属分类' }],
        switchState: [{ required: true, message: '请选择状态' }],
        title: [{ required: true, message: '请输入问题标题' }],
        reply: [{ required: true, message: '请输入问题回复' }],
        relationOriginProblemList: [{ required: true, message: '请选择来源' }],
      },
      videoUploadLoading: false,
      editor: null,
      fileList: [],
      copy: this.$route.query.copy,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      question
        .getQuestionDetail({
          id,
        })
        .then((res) => {
          const {
            internalCode, classificaCode, switchState, title, labelList, attachment,
            reply, relationOriginProblemList, channelName,
          } = res.data;
          this.questionData = {
            id,
            internalCode,
            classificaCode,
            switchState,
            title,
            labelList,
            reply,
            relationOriginProblemList,
            attachment,
            channelName,
          };
          if (this.copy) {
            this.questionData.relationOriginProblemList = [];
          }
          this.questionData.labelList = this.questionData.labelList || [];
          if (attachment) {
            const arr = attachment.split(',');
            arr.forEach((str) => {
              const index = str.lastIndexOf('/');
              const name = str.substring(index + 1, str.length);
              this.fileList.push({
                name,
                url: str,
              });
            });
            this.questionData.attachment = arr;
          }
        }).catch()
    }
    this.getClassListMethod();
  },
  mounted() {
    this.ed();
  },

  methods: {
    ...mapActions('question', ['verifyOnlyMess', 'imagesUploader']),
    beforeCoverUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return isLt2M;
    },
    /**
         * @description 上传图片
         * @param { string } attachment 将附件处理成字符串
         */
    uploadImg(file) {
      const fileImg = file.file;
      const params = new FormData();
      params.append('files', fileImg);
      this.imagesUploader(params).then((res) => {
        if (res?.code === '0') {
          const { attachment } = this.questionData;
          if (!attachment) this.questionData.attachment = [];
          this.questionData.attachment.push(res.data);
        }
      });
    },

    /**
         * @description 删除图片
         */
    removeHandle(res) {
      this.questionData.attachment.forEach((str, deleteIndex) => {
        const index = str.lastIndexOf('/');
        const name = str.substring(index + 1, str.length);
        if (name === res.name) {
          this.questionData.attachment.splice(deleteIndex, 1);
        }
      });
    },
    async codeBlurHandle() {
      const { internalCode } = this.questionData;
      if (internalCode) {
        const { id } = this.$route.query;
        const dt = await this.verifyOnlyMess({
          type: 'internalCode',
          value: internalCode,
          id,
        });
        if (dt.data) {
          this.questionData.internalCode = '';
          this.$message('内部编号重复！');
        }
      }
    },
    ed() {
      // eslint-disable-next-line new-cap
      this.editor = new E(this.$refs.editor);
      const { editor } = this;
      editor.customConfig.onchange = (html) => {
        // 设置数据
        this.questionData.reply = html;
        this.editorText = editor.txt.text();
      };
      editor.customConfig.menus = [
        'head', // 标题
        'bold', // 粗体
        // 'fontSize', // 字号
        'lineFontSize', // 字号
        'fontSpace', // 字间距
        'lineHeight',
        'textIndent', // 首行缩进
        'endTextIndent', // 两端缩进
        'paragraphFront', // 段前距
        'paragraphBack', // 段后距
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        // 'emoticon', // 表情
        'image', // 插入图片
        // 'table', // 表格
        'video', // 插入视频
        // 'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
      ];
      editor.customConfig.colors = [
        'rgba(255,255,255,0.4)', // 透明
        '#000000',
        '#eeece0',
        '#1c487f',
        '#4d80bf',
        '#c24f4a',
        '#8baa4a',
        '#7b5ba1',
        '#46acc8',
        '#f9963b',
        '#ffffff',
      ];
      editor.customConfig.zIndex = 0;
      // 关闭粘贴样式的过滤
      editor.customConfig.pasteFilterStyle = false;
      editor.customConfig.pasteTextHandle = (content) => {
        const reg = /[^-]width:.*?;/gi;
        const reg1 = /<p.*?>.*?<\/p>/gi;
        let cont = content;
        const arr = content.match(reg1);
        if (Array.isArray(arr)) {
          arr.forEach((i) => {
            const reg2 = /^<p/;
            const reg3 = /<\/p>$/;
            const ii = i.replace(reg2, '<span').replace(reg3, '</span>');
            cont = cont.replace(i, ii);
          });
        }
        cont = cont.replace(reg, '');
        return cont;
      };
      // 自定义上传图片
      editor.customConfig.customUploadImg = (files, insert) => {
        const result = this.beforeContentUpload(files[0]);
        if (!result) return;
        const params = new FormData();
        params.append('files', files[0]);
        question.uploader(params).then((res) => {
          const imgUrl = res.data[0].fileUrl;
          insert(imgUrl);
        });
      };
      // 自定义上传视频
      editor.customConfig.customUploadVideo = (files, insert) => {
        if (this.formData.videoThumb === '') {
          this.$message.error('请先上传视频封面');
          return;
        }
        this.videoUploadLoading = true;
        const result = this.beforeVideoUpload(files[0]);
        if (!result) return;
        const params = new FormData();
        params.append('files', files[0]);
        question.uploader(params).then((res) => {
          const imgUrl = res.data[0].fileUrl;
          insert(imgUrl, this.formData.videoThumb);
          this.videoUploadLoading = false;
        });
      };
      editor.create();
      setTimeout(() => {
        editor.txt.html(this.questionData.reply);
        const editorTxt = this.editor.txt.text();
        if (editorTxt !== '') {
          this.editorText = editorTxt;
        }
      }, 1000);
    },
    // 内容图片上传校验
    beforeContentUpload(file) {
      const reg = /(png|jpe?g|)/g;
      const isJPG = reg.test(file.type);
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1MB!');
      }
      return isJPG && isLt1M;
    },
    /**
         * @description 所属分类模糊搜索
         * @param { classificaName } 搜索名字
         */
    getClassListMethod(dt) {
      question
        .getClassList({
          classificaName: dt,
        })
        .then((res) => {
          this.classList = res.data.data;
        });
    },
    getSourceList(node, resolve) {
      question
        .getOriginList({
          channelCode: node.root ? '' : node.data.channelCode,
        })
        .then((res) => {
          resolve(res.data);
        });
    },
    async saveHandle() {
      const { internalCode } = this.questionData;
      if (this.copy && internalCode) {
        const { id } = this.$route.query;
        const dt = await this.verifyOnlyMess({
          type: 'internalCode',
          value: internalCode,
          id,
        });
        if (dt.data) {
          this.questionData.internalCode = '';
          this.$message('内部编号重复！');
        } else {
          this.save();
        }
      } else {
        this.save();
      }
    },
    save: _.debounce(
      // analysisImg(this.questionData.reply, true)
      function save() {
        if (analysisImg(this.questionData.reply, true) > 9) {
          this.$message('最多上传9张！');
          return;
        }

        this.$refs.form.validate((valid) => {
          if (valid) {
            const { realName } = this.userInfo;
            this.questionData.userName = realName;
            const { attachment } = this.questionData;
            this.questionData.attachment = attachment?.toString();
            if (this.copy) {
              this.questionData.id = '';
            }
            question.saveProblem(this.questionData).then(() => {
              this.$message.success('保存成功');

              const loadingInstance = Loading.service({
                fullscreen: true,
              });

              setTimeout(() => {
                loadingInstance.close();
                this.$router.push({
                  path: '/question/list',
                });
              }, 2000);
            });
          }
        });
      },
      1000,
      {
        leading: true,
      },
    ),
  },
};
</script>

<style lang="scss" scoped>
.header_name {
  background: #fff;
  padding: 5px 32px 15px;
}
.question-list {
  height: 1000px;
  margin: 32px;
  .title {
    padding: 20px 32px;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
  }
}
.questionForm {
  background: #fff;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /deep/ .el-form-item {
    width: 50%;
  }
}

.edit_wrap {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.save_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 295px);
  background: #fff;
  display: flex;
  justify-content: flex-end;
  padding: 12px 32px;
}
/deep/.w-e-toolbar {
  width: 815px;
  flex-wrap: wrap;
}
.set_wrap {
  width: 815px;
}
/deep/.w-e-droplist {
  z-index: 9999;
}
</style>
